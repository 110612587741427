import { Images } from "../../../themes";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { AppRoute } from "../../../router/routerConstants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ConsumerTab from "../../../components/ConsumerTab";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUser } from "../../../hooks/useStore";
import SearchFilter from "../../../components/SearchFilter";
import { getStorage, getStorageJson } from "../../../utils/localStorage";
import {
  GetTags,
  SearchCreator,
  SetFilter,
  UpdateFav,
  LoadSubscription,
  ProfileView

} from "../../../utils/api/actionConstants";
import { getData, PostData, saveData,showErrorInUI } from "../../../utils/api/apiHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import socket from "../../../socket/socket";
import { IsUserSubscribed } from "../../../utils/Common";
import BlurryLoadingImage from "../../../components/BlurryLoadingImage";

const Search = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const searchData = useSelector((state) => {
    return state.creator.searchList;
  });
  const limit = 12;
  const [searchList, setSearchList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [offset, setOffset] = useState(0);

  const [filters, setFilters] = useState({});
  const currentUser = useUser();
  //console.log(currentUser);
  const hasMore1 = useSelector((state) => {
    return state.creator.hasMore;
  });

  const [hasMore, setHasMore] = useState(hasMore1);

  const storeFilterdData = useSelector((state) => {
    return state.creator.filter;
  });
  useEffect(() => {
    if(currentUser.id){
      socket.connect();
      socket.emit("sign-in", currentUser);
    }
    
  }, []);
  /*
  useEffect(() => {
    dispatch(
      getData({
        action: LoadSubscription,
      })
    );
  }, []); */

  useEffect(() => {
    dispatch(getData({ action: GetTags }));
    if (searchData.length > 0) {
      return;
    }

    if (Object.keys(storeFilterdData).length > 0) {
      fetchData(storeFilterdData);
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (searchData) {
      setSearchList(searchData);
    }
  }, [searchData]);

  useEffect(() => {
    if (storeFilterdData) {
      setFilters(storeFilterdData);
    }
  }, [storeFilterdData]);

  useEffect(() => {
    const root = document.getElementById("scrollableDiv");
    if (hasMore && root.scrollHeight <= root.clientHeight) {
      fetchData(filters, false, offset);
    }
  }, [hasMore, offset]);

  const fetchData = (filData = {}, reset = true, offset = 0) => {
    const filterData = {
      tags: filData.tags,
      age: filData.minAgeValue
        ? [
            filData.minAgeValue ? `${filData.minAgeValue}` : "",
            filData.maxAgeValue ? `${filData.maxAgeValue}` : "",
          ]
        : undefined,
      ethnicity:
        filData.ethnicity === "DEFAULT" ||
        filData.ethnicity === "Select ethnicity"
          ? ""
          : filData.ethnicity,
      bodyType:
        filData.bodyType === "DEFAULT" ||
        filData.bodyType === "Select body type"
          ? ""
          : filData.bodyType,
      hairColor:
        filData.hairColor === "DEFAULT" ||
        filData.hairColor === "Select hair color"
          ? ""
          : filData.hairColor,
      searchText: filData.searchText,
      limit: limit,
      offset: offset,
    };

    dispatch(
      PostData({
        action: SearchCreator,
        data: filterData,
      })
    ).then((r) => {
      
      if (r.length === limit) {
        setHasMore(true);
        dispatch({ type: "HasMore", payload: true });
      } else {
        setHasMore(false);
        dispatch({ type: "HasMore", payload: false });
      }

      let list = [];
      if (reset) {
        list = r;
      } else {
        //if (IsUserSubscribed(currentUser)) {
          list = [...searchList, ...r];
        /*} else {
          list = r;
          setHasMore(false);
          dispatch({ type: "HasMore", payload: false });
        }*/
      }
      //console.log(list);
      setOffset(list.length);
      dispatch({ type: SearchCreator, payload: list });
     
    });
  };

  const onSearchTextChange = (value) => {
    setOffset(0);
    let currentFilter = { ...filters, searchText: value };
    dispatch({ type: SetFilter, payload: currentFilter });

    if (value.length > 2) {
      fetchData(currentFilter);
    }
    if (value.length === 0) {
      fetchData(currentFilter);
    }
  };
  const filterSelected = (n_filters = {}) => {
    let currentFilter = { ...filters, ...n_filters };

    setOffset(0);
    dispatch({ type: SetFilter, payload: currentFilter });
    fetchData(currentFilter);
    setShowFilter(false);
  };
  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  const profileClkd = (id) => {
    dispatch({ type: "ResetProfile" });
    dispatch(
      saveData(
        {
          action: ProfileView,
          data: {
            userId: id,
          },
        },
        true
      )
    ).then((r) => {
      NavigateTo(`${AppRoute.Search_Profile}/${id}`);
    });
   
  };

  const favClkd = (e, id) => {
    
    e.stopPropagation();
    if(currentUser?.id){
      dispatch(
        saveData({
          action: UpdateFav,
          data: {
            userId: id,
          },
        })
      );
    }else{
      showErrorInUI("Please login to add your favourite");
      return;
    }
    
  };

  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };

 

  const CreatorProfileComponent = () => {
    if (searchList.length === 0 && apiCall ===true) {
      return <div>No user found</div>;
    }
    if (searchList.length === 0 && apiCall ===false) {
      return <div>No user found</div>;
    }
    
    /*const sortedDataByBackend = searchList.slice().sort((a, b) => {
      const orderA = a.sortCreators.length > 0 ? a.sortCreators[0].orderId : Infinity;
      const orderB = b.sortCreators.length > 0 ? b.sortCreators[0].orderId : Infinity;
      return orderA - orderB;
    }); */
    
    return searchList.map((row, index) => {
      return (
        <div className="col-6" onClick={(e) => profileClkd(row.id)} key={index}>
          <div className="inner-uploaded">
            <a>
              <span
                className={`fav-btn ${row?.favorites?.length > 0 && "active"}`}
                onClick={(e) => favClkd(e, row.id)}
              ></span>
            </a>
            <figure>
              <BlurryLoadingImage
                profilePic={row.profilePic ? row.profilePic : null}
                isBlur={row.isBlur}
              />
            </figure>
          </div>
        </div>
      );
    });
  };
  
  return (
    <div className="wrapper">
      <Header />
      <div className="container">
      
      
        <div className="setup-profile-wrap search-wrap favourite-icon">
          <div className="my-ac-form-info mt-5">
            <div className="form-inner-holder">
              <p>Real girls. Custom content. Pick one, send a message, take it from there.</p>
            </div>
          </div>
        {!isUserLogin() && (
        <div className="text-center mts" >
          Already have an Account?{" "}
          <span onClick={() => NavigateTo(AppRoute.LOGIN)}>Login here</span>
        </div>
        )}
       
        {/*
          <div className="heading-block space-between">
            { /*<h1 className="left-align large-heading">Search</h1> */ /*}
            <h1></h1>
            <span className="filter-link">
              <img
                src={Images.filter_icon}
                alt=""
                onClick={() => filterVisibility(true)}
              />
            </span>
          </div>
          <div className="searh-block">
            <input
              type="text"
              placeholder="Search..."
              value={filters.searchText}
              className="search-box"
              onChange={(event) => onSearchTextChange(event.target.value)}
            />
          </div> */ }
          <div id="scrollableDiv" className="gallery-img">
        
            <div className="uploded-imges">
              <InfiniteScroll
                style={{ overflow: "none" }}
                dataLength={searchList.length} //This is important field to render the next data
                next={() => fetchData(filters, false, offset)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <div className="row">{CreatorProfileComponent()}</div>
              </InfiniteScroll>
            </div>
          </div>
          {
            (currentUser?.id)?<ConsumerTab activeTab={0} />:null
          }
          
          {showFilter && (
            <SearchFilter
              modalVisibility={filterVisibility}
              filters={filters}
              filtersSubmit={filterSelected}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Search;
