import { useEffect,useState } from "react";
import { Images } from "../themes";
import { useCustomNavigate } from "../utils/hooks/useCustomNavigate";
import { useDispatch } from "react-redux";
import { useCountNotification } from "../hooks/useStore";
import { AppRoute } from "../router/routerConstants";
import { LogOutUser, IsConsumerActive,UnReadAllChatMessage } from "../utils/api/actionConstants";
import { getData } from "../utils/api/apiHelper";
import socket from "../socket/socket";
import {
  setStorage,
  getStorage,
  getStorageJson,
  removeStorage,
} from "../utils/localStorage";
import { IsUserSubscribed } from "../utils/Common";
import { parse } from "mustache";

const Header = ({ showLogout = true }) => {
  const [NavigateTo] = useCustomNavigate();
  const notifications = useCountNotification();
  const [defaultChecked, setDefaultChecked] = useState(
    getStorage("theme") || "yes"
  );
  const token = getStorage("token");
  const user = getStorageJson("user");
  const dispatch = useDispatch();
  const LogOut = () => {
    if (socket) {
      socket.close();
    }
    removeStorage("user");
    removeStorage("token");
    dispatch({ type: LogOutUser });
    NavigateTo(AppRoute.LOGIN, true);
  };

  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };
  const fetchUnreadMessages = async () => {
    if (isUserLogin()) {
      try {
        dispatch(getData({action: UnReadAllChatMessage, }));
      } catch (error) {
        console.error("Error fetching unread messages:", error);
      }
    }
  };
  
  useEffect(() => {
    if (isUserLogin()) {
      const currentUser = getStorageJson("user");
      socket.emit("sign-in", currentUser);
      setupSocketListeners();
      fetchUnreadMessages();
    }
  }, []);
  
  useEffect(() => () => socket.off("message", onMessageRecieved), []);

  const onClientDisconnected = () => {
    console.log(
      "Connection Lost from server please check your connection.",
      "Error!"
    );
  };


  /**
   * Established new connection if reconnected.
   */
  const onReconnection = () => {
    const currentUser = getStorageJson("user");
    if (currentUser) {
      socket.emit("sign-in", currentUser);
      console.log("Connection Established.", "Reconnected!");
    }
  };

  const onMessageRecieved = (message) => {
    fetchUnreadMessages();
  };

  const setupSocketListeners = () => {
    //console.log(socket);
    socket.on("message", onMessageRecieved);
    socket.on("reconnect", onReconnection);
    socket.on("disconnect", onClientDisconnected);

  };



  const handleBellClick = () => {
    NavigateTo(AppRoute.Chat);
  };

 
  const handleSwitchChange = (event) => {
    const body = document.body;
    const theme = event.target.id;
    setDefaultChecked(theme);
    setStorage("theme", theme);

    if (theme === "yes") {
      body.classList.add("dark-mode");
      body.classList.remove("light-mode");
    } else {
      body.classList.add("light-mode");
      body.classList.remove("dark-mode");
    }
  };

  useEffect(() => {
    const savedTheme = getStorage("theme") || "yes";
    const body = document.body;

    if (savedTheme === "yes") {
      body.classList.add("dark-mode");
      body.classList.remove("light-mode");
    } else {
      body.classList.add("light-mode");
      body.classList.remove("dark-mode");
    }
  }, []);



  /*const fetchData = async () => {
    if (!isUserLogin() || isCreator()) return;
    dispatch(
      getData({
        action: IsConsumerActive
      })
    ).then((resp) => {
      if (!resp || resp !== 1) {
        LogOut(); 
      }
    });
  }; */

  // useEffect hook to fire the API call on mount and at intervals
  /*useEffect(() => {
    const intervalId = setInterval(fetchData, 60000); // Call every 5 seconds
    return () => clearInterval(intervalId);
  }, []); */ // Empty dependency array ensures the effect runs only once on mount


  const onWomenContinue = () => {
      NavigateTo(AppRoute.BENEFITS, false);
  };

  const isCreator = () => {
    const user = getStorageJson("user");
    return user.role === "Creator";
  };
  const isSetupComplete = () => {
    const user = getStorageJson("user");
    return user.isSetupComplete;
  };

  const IsSubscribed = () => {
    const user = getStorageJson("user");
    return IsUserSubscribed(user);
  };
  return (
    <section className="header">
      <div className="header-container ">
        <div className="desktop-logo">
          <span
            onClick={() => {
              if (isUserLogin()) {
                if (isCreator()) {
                  if (isSetupComplete()) {
                    NavigateTo(AppRoute.CreatorHome, true);
                  } else {
                    NavigateTo(AppRoute.REGISTER, true);
                  }
                } else {
                  
                  NavigateTo(AppRoute.CONS_Search, true);
                }
              } else {
                NavigateTo(AppRoute.SPLASH, false, { from: "logo" });
              }
            }}
          >
            <img src={Images.header_logo} alt="logo" />
          </span>
        </div>

        { (isUserLogin())?
        
      
        <div className="notification-bell bellContainer pointer" onClick={handleBellClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          width={50}
          height={50}
          viewBox="0 0 611.999 611.999"
        >
          <path d="M570.107 500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203C381.969 34 347.883 0 306.001 0c-41.883 0-75.968 34.002-76.121 75.849-70.682 29.804-120.425 99.801-120.425 181.203v84.578c-.046 3.181-2.522 129.251-67.561 158.622a17.257 17.257 0 0 0 7.103 32.986h164.88c3.38 18.594 12.172 35.892 25.619 49.903 17.86 18.608 41.479 28.856 66.502 28.856 25.025 0 48.644-10.248 66.502-28.856 13.449-14.012 22.241-31.311 25.619-49.903h164.88a17.26 17.26 0 0 0 16.872-13.626 17.25 17.25 0 0 0-9.764-19.358zm-85.673-60.395c6.837 20.728 16.518 41.544 30.246 58.866H97.32c13.726-17.32 23.407-38.135 30.244-58.866h356.87zM306.001 34.515c18.945 0 34.963 12.73 39.975 30.082-12.912-2.678-26.282-4.09-39.975-4.09s-27.063 1.411-39.975 4.09c5.013-17.351 21.031-30.082 39.975-30.082zM143.97 341.736v-84.685c0-89.343 72.686-162.029 162.031-162.029s162.031 72.686 162.031 162.029v84.826c.023 2.596.427 29.879 7.303 63.465H136.663c6.88-33.618 7.286-60.949 7.307-63.606zm162.031 235.749c-26.341 0-49.33-18.992-56.709-44.246h113.416c-7.379 25.254-30.364 44.246-56.707 44.246z" />
          <path d="M306.001 119.235c-74.25 0-134.657 60.405-134.657 134.654 0 9.531 7.727 17.258 17.258 17.258 9.531 0 17.258-7.727 17.258-17.258 0-55.217 44.923-100.139 100.142-100.139 9.531 0 17.258-7.727 17.258-17.258-.001-9.532-7.728-17.257-17.259-17.257z" />
        </svg>
         <span className="notification-bell badge" >{notifications}</span>
        </div>
        : null
        }
       {/*<div className={!isUserLogin() ? 'hdr-rt' : ''}>
        <div className="hdr-switch">
          
            <span className="btn-switch">					
              <input type="radio" id="yes" name="switch" onChange={(e) => handleSwitchChange(e)} checked={(defaultChecked==='yes')?true:false} className="btn-switch__radio btn-switch__radio_yes" />
              <input defaultChecked type="radio" onChange={(e) => handleSwitchChange(e)} checked={(defaultChecked==='no')?true:false}  id="no" name="switch" className="btn-switch__radio btn-switch__radio_no" />		
              <label htmlFor="yes" className="btn-switch__label btn-switch__label_yes"><span className="btn-switch__txt">Light</span></label>
              <label htmlFor="no" className="btn-switch__label btn-switch__label_no"><span className="btn-switch__txt">Dark</span></label>
            </span>
          </div> */ }

          {!isUserLogin() &&  (
        <div onClick={onWomenContinue}>
            <span className="submit hdr-btn">
             Become a Creator
            </span>
            <div className="btn-loader"></div>
        </div>
        )}
       {/*  </div>  */}

        {showLogout && token && user && (
          <div className="right-block">
            <span className="log-out-btn" onClick={LogOut}>
              Log Out
            </span>
          </div>
        )}

      </div>
    </section>
  );
};

export default Header;
